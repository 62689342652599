import { API_URL_DEV, API_URL } from "../config/environment";

function handleInvalidToken() {
  window.sessionStorage.setItem("secret", null);
  window.sessionStorage.setItem("permissions", null);
  window.location.href = "/login";
}

function handleCatchError(error) {
  if (error.code === 504) {
    handleInvalidToken();
  } else {
    throw error;
  }
}

function createAPI() {
  function get(path, withAuth=true) {
    return fetch(getPath(path), {
      method: "GET",
      headers: getHeaders(withAuth),
    })
      .then(getJson)
      .catch(handleCatchError);
  }

  function post(path, body, withAuth=true) {
    return fetch(getPath(path), {
      method: "POST",
      headers: getHeaders(withAuth),
      body: JSON.stringify(body),
      mode: "cors",
    })
      .then(getJson)
      .catch(handleCatchError);
  }

  function put(path, body, withAuth=true) {
    return fetch(getPath(path), {
      method: "PUT",
      headers: getHeaders(withAuth),
      body: JSON.stringify(body),
      mode: "cors",
    }).then(getJson)
    .catch(handleCatchError);
  }

  function getPath(path) {
    const API = process.env.REACT_APP_ENV === "develop" ? API_URL_DEV : API_URL;
    return `${API}${path}`;
  }

  function getHeaders(withAuth = false) {
    let authToken = "";
    if (withAuth) {
      authToken = window.sessionStorage.getItem("secret");
    }

    authToken = authToken?.replace(/"/gi, "");

    const headers = {
      "Content-Type": "application/json",
      ...(authToken && { Authorization: `Bearer ${authToken}` }),
      // "Access-Control-Request-Origin": "*"
    };

    return headers;
  }

  async function getJson(data) {
    if (data.status !== 200) {
      throw await data.json();
    } else {
      return data.json();
    }
  }

  return {
    get,
    post,
    put,
  };
}

export default createAPI();
