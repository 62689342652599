import React, { useEffect, useState } from "react";
import { Outlet } from "react-router";
import { Box } from "@mui/material";
import useLocalStorage from "../hooks/useLocalStorage";
import Menu from "../components/Menu";
import API from "../config/api";
import styles from "./layout.module.scss";

const drawerWidth = 240;

const AuthLayout = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openMenu, setOpenMenu] = useState(true);
  const [isPermissionsLoading, setIsPermissionsLoading] = useState(true);
  const [permissionsStorage, setPermissionsStorage] =
    useLocalStorage("permissions");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleForMenuCollapse = (option) => {
    setOpenMenu(option);
  };

  useEffect(() => {
    (async () => {
      try {
        if (!permissionsStorage) {
          const permissions = await API.get("model_permissions");
          if (permissions) {
            setPermissionsStorage(JSON.stringify(permissions));
            setIsPermissionsLoading(false);
          }
        } else {
          setIsPermissionsLoading(false);
        }
      } catch (e) {
        console.log("ERRROR---", e);
      }
    })();
  }, []);

  return (
    <Box className={styles.container} sx={{ display: "flex" }}>
      <Box
        className={styles.menuContainer}
        component="nav"
        sx={{
          width: { sm: openMenu ? drawerWidth : 0 },
          flexShrink: { sm: 0 },
        }}
      >
        <Menu
          openMenu={openMenu}
          handleForMenuCollapse={handleForMenuCollapse}
        />
      </Box>
      <Box
        className={styles.contentContainer}
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          mt: 10,
          width: { sm: `calc(100% - ${openMenu ? drawerWidth : 0}px)` },
        }}
      >
        { isPermissionsLoading ? <p>loading....</p> : <Outlet /> }
      </Box>
    </Box>
  );
};

export default AuthLayout;
