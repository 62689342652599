import * as React from "react";
import {
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  DrawerHeader,
  Divider,
  Button
} from "@mui/material";

import {
  StoreOutlined,
  AccountBalanceOutlined,
  BadgeOutlined,
  MiscellaneousServicesOutlined,
  FindInPageOutlined,
  DisabledByDefaultOutlined,
  Receipt,
  Payment,
  Store,
  ChevronLeft,
  ArticleOutlined,
  CardTravelOutlined,
  Logout
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useLocation, useNavigate } from "react-router";

//import Reports from "../pages/Reportes/Reports";

import styles from "./menu.module.scss";

const URL = {
  members: "/miembros",
  reportes: "/reportes",
  configuracion: "/configuracion",
};
const drawerWidth = 230;
function Menu({ openMenu, handleForMenuCollapse }) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    handleForMenuCollapse(true);
  };

  const handleDrawerClose = () => {
    handleForMenuCollapse(false);
  };

  const handleLogout = () => {
    navigate("logout");
  }

  return (
    <div>
      <AppBar
        className={styles.menuContainer}
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${openMenu ? drawerWidth : 0}px)` },
        }}
      >
        <Toolbar position="fixed">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(openMenu && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1}}>
            Sistema de membresias{" "}
            {process.env.REACT_APP_DATABASE_ENV === "staging"
              ? "(entorno de PRUEBA)"
              : ""}
          </Typography>
          <IconButton color="inherit" onClick={handleLogout}><Logout/></IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="persistent" anchor="left" open={openMenu}>
        <div>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeft />
          </IconButton>
        </div>
        <Divider />
        <List component="nav">
          {[
            {
              text: "Miembros",
              icon: <BadgeOutlined />,
              url: URL.members,
              subMenu: [
                {
                  text: "Fallecidos",
                  icon: <DisabledByDefaultOutlined />,
                  url: "/fallecidos",
                },
                {
                  text: "Vista documentos",
                  icon: <FindInPageOutlined />,
                  url: "/documentos",
                },
              ],
            },
            {
              text: "Facturas",
              icon: <Receipt />,
              url: "/recibos",
              subMenu: [
                {
                  text: "Fact. nueva",
                  icon: <Store />,
                  url: "/compania",
                },
                {
                  text: "Facts. pendientes",
                  icon: <Store />,
                  url: "/credito",
                },
              ],
            },
            /*{
            text: "Configuracion",
            icon: <MiscellaneousServicesOutlined />,
            url: "/",
          },*/
            {
              text: "Servicios",
              icon: <MiscellaneousServicesOutlined />,
              url: "/servicios",
            },
            { text: "Companias", icon: <StoreOutlined />, url: "/companias" },
            /*{
              text: "Proveedor",
              icon: <StoreOutlined />,
              url: "/proveedor",
            },*/
            {
              text: "Catalogo cuentas",
              icon: <AccountBalanceOutlined />,
              url: "/catalogo_cuenta",
            },
            {
              text: "Comprobantes",
              icon: <CardTravelOutlined />,
              url: "/comprobantes",
            },
            {
              text: "Reportes",
              icon: <ArticleOutlined />,
              url: "/reportes",
            },
            /*{
              text: "Deposito",
              icon: <ArticleOutlined />,
              url: "/deposito",
            },*/
          ].map((option, index) => (
            <div key={`${option.url}-${index}`}>
              <ListItemButton
                component={Link}
                to={option.url}
                selected={pathname === option.url}
                sx={{
                  justifyContent: openMenu ? "initial" : "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: openMenu ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {option.icon}
                </ListItemIcon>
                <ListItemText primary={option.text} />
              </ListItemButton>

              {option.hasOwnProperty("subMenu")
                ? option.subMenu.map((subMenu, index) => (
                    <Collapse
                      key={`${subMenu.url}-${index}`}
                      in={true}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List component="div" disablePadding>
                        <ListItemButton
                          sx={{
                            pl: 4,
                            justifyContent: openMenu ? "initial" : "center",
                          }}
                          component={Link}
                          to={`${option.url}${subMenu.url}`}
                          selected={pathname === `${option.url}${subMenu.url}`}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: openMenu ? 3 : "auto",
                              justifyContent: "center",
                            }}
                          >
                            {subMenu.icon}
                          </ListItemIcon>
                          <ListItemText primary={subMenu.text} />
                        </ListItemButton>
                      </List>
                    </Collapse>
                  ))
                : null}
            </div>
          ))}
        </List>
      </Drawer>
    </div>
  );
  /*
  return (
    <Box displayPrint="none">
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="Menu"
            />
            <Typography variant="h6" className={classes.title}>
              <p>SIS SOCIEDAD <span>V1.6.0 (BETA)</span></p>
            </Typography>

            <Typography className="menu_space">
              <Link className={classes.linkClass} to="/miembros">
                Miembros
              </Link>
            </Typography>
            <Typography className="menu_space">
              <Link className={classes.linkClass} to="/servicios">
                Servicios
              </Link>
            </Typography>
            <Typography className="menu_space">
              <Link className={classes.linkClass} to="/companias">
                Companias
              </Link>
            </Typography>
            <Typography className="menu_space">
              <Link className={classes.linkClass} to="/catalogo_cuenta">
                Catalogo cuentas
              </Link>
            </Typography>
                    
              <Select
              value={"cobro"}
            >
              <MenuItem                
                value="cobro"
              >
                <Typography className={classes.linkClass}>Cobros</Typography>                
              </MenuItem>
              <MenuItem value="member">
              <Link 
                  value="miembro"
                  to="/factura/miembro"
                >
                  Miembro
                </Link>
              </MenuItem>
              <MenuItem value="compania">
                <Link 
                  value="company"
                  //className={classes.linkClass} 
                  to="/factura/compania"
                >
                  Compania
                </Link>
              </MenuItem>              
            </Select>                  
         
            <ReportTypeProvider>
              <Reports />
            </ReportTypeProvider>
          </Toolbar>
        </AppBar>
      </div>
    </Box>
  );
  */
}

/*
            <Typography className="menu_space">
              <Link className={classes.linkClass} to="/formulario_cobro">
                Cobros
              </Link>
            </Typography>
            */

/*
            <Typography>
              <Link className={classes.linkClass} to="/mensajeria">
                Mensajeria
              </Link>
            </Typography>
            */
export default Menu;
