import * as React from "react";
import { TextField, Button } from "@mui/material";
import { useNavigate, Navigate } from "react-router";
import useLocalStorage from "../../hooks/useLocalStorage";
import api from "../../config/api";
import sisaLogo from "../../assests/anestesiologia.png";
import style from "./login.module.scss";

function LogIn() {
  const [credential, setCredential] = React.useState({ user: "", pass: "" });
  const [isLogging, setIsLogging] = React.useState(false);
  const [errors, setErrors] = React.useState({ user: "", pass: "" });
  const [storage, setStorage] = useLocalStorage("secret", null);
  const navigate = useNavigate();
  function goToMember() {
    navigate("miembros");
  }

  async function handlerLogIn() {
    try {
      if (!credential.user || !credential.pass) {
        setErrors({ user: "Usuario o contrasena incorrecta", pass: "" });
      } else {
        setIsLogging(true);
        const payload = await api.post("login", {
          email: credential.user,
          password: credential.pass,
        }, false);

        setStorage(payload);
        goToMember();
      }

      /*
      errorUser = credential.user === "";
      errorUser = !errorUser ? credential.user !== "sisa" : errorUser;
      
      errorUser &&
        setErrors((prev) => ({ ...prev, user: "El usuario es incorrecto" }));
      errorPass = credential.pass === "";
      errorPass = !errorPass ? credential.pass !== "123" : errorPass;
      errorPass &&
        setErrors((prev) => ({ ...prev, pass: "La contrasena es incorrecta" }));
      
      if (credential.user === "sisa" && credential.pass === "123") {
        setStorage(Date.now());
        goToMember();
      }
*/
    } catch (error) {
      console.log("ERROR***", error);
      setErrors({ user: "Usuario o contrasena incorrecta", pass: "" });
      setStorage(null);
      setIsLogging(false);
    }
  }

  if (storage) {
    return <Navigate to="/miembros" replace />;
  }
  return (
    <div className={style.login_container}>
      <div className={style.logo_container}>
        <img src={sisaLogo} alt="sisa logotipo" />
      </div>
      <div className={style.txt_container}>
        <div className={style.txt_space}>
          <TextField
            placeholder="Correo"
            name="user"
            fullWidth
            //classes={{ root: style.login_txt }}
            disabled={isLogging}
            error={errors.user !== "" && errors.user}
            helperText={errors.user !== "" && errors.user}
            onChange={({ target: { name, value } }) =>
              setCredential((prev) => ({ ...prev, [name]: value }))
            }
          />
        </div>
        <div className={style.txt_space}>
          <TextField
            placeholder="Contraseña"
            name="pass"
            //classes={{ root: "login_txt" }}
            fullWidth
            type="password"
            disabled={isLogging}
            error={errors.pass !== "" && errors.pass}
            helperText={errors.pass !== "" && errors.pass}
            onChange={({ target: { name, value } }) =>
              setCredential((prev) => ({ ...prev, [name]: value }))
            }
          />
        </div>

        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handlerLogIn}
        >
          Iniciar sesión
        </Button>
      </div>
    </div>
  );
}

export default LogIn;
